.overlay {
	width: 100%;
	position: fixed;
	z-index: 1000;
	opacity: 0.2;
	pointer-events: none;
}

/* @import "../node_modules/bootstrap/dist/css/bootstrap.min.css"; */
/* @import "../node_modules/font-awesome/css/font-awesome.min.css"; */
@import '../node_modules/@fortawesome/fontawesome-free/css/all.css';
/* @import "../node_modules/alertifyjs/build/css/alertify.min.css"; */
/* @import "../node_modules/alertifyjs/build/css/themes/bootstrap.min.css"; */

@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Fira+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
html,
body {
	font-family: 'Montserrat', sans-serif;
}

input.ng-invalid.ng-touched {
	border: 2px solid red;
}

.app-rounded {
	border-radius: 20px;
}

.modal-content {
	border-radius: 1.25rem !important;
}

.modal-dialog {
	margin: 15vh auto 0px auto;
}

.fade.modal-backdrop.show {
	backdrop-filter: blur(9px);
}

.ajs-message {
	border-color: transparent !important;
	border-radius: 1.25rem !important;
}

.app-btn-success {
	padding: 10px 40px;
	color: #fefefe !important;
	background-color: #3caa36 !important;
	border: 3px solid !important;
	border-color: #3caa36 !important;
	font-weight: 600 !important;
	font-family: Montserrat !important;
}

.app-btn-success:hover:not(:disabled) {
	background: none !important;
	background-color: #fefefe !important;
	color: #3caa36 !important;
	border-color: #3caa36 !important;
}

@media (max-width: 480px) {
	.modal-dialog {
		max-width: 80%;
	}
}

.table > :not(caption) > * > * {
	background-color: inherit !important;
}

/*not-found*/

#not-found.app-container {
	background-color: #feb969;
}

#not-found .container {
	background-color: transparent;
	height: 100vh;
	padding-top: 20vh;
}

#not-found .app-not-found {
	background-color: white;
	border-radius: 1.25rem;
}

#not-found .light-blue {
	color: #11cdef;
}

#not-found h1,
.h1 {
	font-size: 7rem;
	font-weight: 600;
	color: #352641;
}

#not-found h2,
.h2 {
	color: #352641;
	font-size: 2.5rem;
}

#not-found h5,
.h5 {
	color: #352641;
}

@media (max-width: 480px) {
	#not-found h1,
	.h1 {
		font-size: 4rem;
	}

	#not-found h2,
	.h2 {
		font-size: 2rem;
	}
}

.link-style {
	color: blue;
	cursor: pointer;
	text-decoration: underline;
}
